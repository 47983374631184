import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./contact.css";

function Contact() {
  const [formData, setFormData] = useState({
    fullName: "",
    emailAddress: "",
    phoneNumber: "",
    subject: "",
    userMessage: "",
    otp: "",
    enteredOtp: "",
  });

  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [generatedOtp, setGeneratedOtp] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendOtp = () => {
    const otp = Math.floor(100000 + Math.random() * 900000).toString();
    setGeneratedOtp(otp);

    const templateParams = {
      to_email: formData.emailAddress,
      message: `Your OTP is ${otp}`,
    };

    emailjs
      .send(
        "YOUR_SERVICE_ID",
        "YOUR_TEMPLATE_ID",
        templateParams,
        "YOUR_USER_ID"
      )
      .then(() => {
        setOtpSent(true);
        alert("OTP sent to your email address");
      })
      .catch((error) => {
        console.error("Error sending OTP:", error);
        alert("Failed to send OTP");
      });
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    if (formData.enteredOtp === generatedOtp) {
      setOtpVerified(true);
      alert("OTP verified successfully");
    } else {
      alert("Invalid OTP");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!otpVerified) {
      alert("Please verify your email with the OTP");
      return;
    }
    // Handle form submission logic here
    console.log("Form data submitted:", formData);
  };

  return (
    <div>
      <div className="innerBanner">
        <img
          className="baner_img_page"
          src="img/default-banner.jpg"
          alt="Website Design and Development"
        />
      </div>
      <section className="contact">
        <div className="container">
          <h3 className="text-center text-uppercase">Contact Us</h3>
          <p className="text-center w-75 m-auto">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
            interdum purus at sem ornare sodales. Morbi leo nulla, pharetra vel
            felis nec, ullamcorper condimentum quam.
          </p>
          <div className="row">
            <div className="col-md-4 my-5">
              <div className="ra-contact-card border-0">
                <div className=" card-body text-center">
                  <i className="fa fa-phone fa-5x mb-3" aria-hidden="true"></i>
                  <h4 className="text-uppercase mb-4">Call Us</h4>
                  <div className="ra-card-flag">
                  <a href="tel: +91 9062425758"><img src="img/indiaFlag.png" alt="INDIA" class="img-fluid" />+91 9062425758</a>

                  <a href="tel: +1 724-924-4837"><img src="img/usaFlag.png" alt="USA" class="img-fluid"/>+1 724-924-4837</a>
                </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 my-5">
              <div className="ra-contact-card border-0">
                <div className="card-body text-center">
                  <i
                    className="fa-solid fa-location-dot fa-5x mb-3"
                    aria-hidden="true"
                  ></i>
                  <h4 className="text-uppercase mb-4">Office Location</h4>
                  <address>Stesalit Tower, E-2-3, GP Block, Sector V, Bidhannagar, Kolkata, West Bengal 700091 (3rd Floor)</address>
                </div>
              </div>
            </div>
            <div className="col-md-4 my-5">
              <div className="ra-contact-card border-0">
                <div className="card-body text-center">
                  <i className="fa-solid fa-envelope fa-5x mb-3" aria-hidden="true"></i>
                  <h4 className="text-uppercase mb-4 ">Email</h4>
                  <p>connect@thewebaid.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
      <h3 className=" ra-letsconnect text-center P-5">Lets Connect</h3>
        <div className="ra-contacr-from">
          <div className="row justify-content-lg-center">
            <div className="col-12 col-lg-9">
              <div className="bg-white border rounded shadow-sm overflow-hidden">
                <form onSubmit={handleSubmit}>
                  <div className="row gy-4 gy-xl-5 p-4 p-xl-5">
                    <div className="col-12">
                      <label htmlFor="fullName" className="ra-form-label">
                        Full Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text-fullName"
                        className="form-control"
                        id="fullName"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="emailAddress" className="form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-envelope"
                            viewBox="0 0 16 16"
                          >
                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                          </svg>
                        </span>
                        <input
                          type="emailAddress"
                          className="form-control"
                          id="emailAddress"
                          name="emailAddress"
                          value={formData.emailAddress}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary mt-2"
                        onClick={sendOtp}
                      >
                        Send OTP
                      </button>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="otp" className="form-label">
                        Enter OTP
                      </label>
                      <input
                        type="text-otp"
                        className="form-control"
                        id="otp"
                        name="enteredOtp"
                        value={formData.enteredOtp}
                        onChange={handleChange}
                        required
                      />
                      <button
                        type="button"
                        className="btn btn-primary mt-2"
                        onClick={verifyOtp}
                      >
                        Verify OTP
                      </button>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="phoneNumber" className="form-label">
                        Phone Number
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-telephone"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                          </svg>
                        </span>
                        <input
                          type="tel"
                          className="form-control"
                          id="phoneNumber"
                          name="phoneNumber"
                          value={formData.phoneNumber}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="subject" className="form-label">
                        Subject <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text-subject"
                        className="form-control"
                        id="subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <label htmlFor="userMessage" className="form-label">
                        Message <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        id="userMessage"
                        name="userMessage"
                        rows="3"
                        value={formData.userMessage}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>
                    <div className="col-12">
                      <div className="d-grid">
                        <button
                          className="ra-btn btn-primary btn-lg"
                          type="submit"
                          disabled={!otpVerified}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
