import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./home.css";
import "./websiteDevelopment.css";

function Digitalevelopment() {


  const [Digital, setDigital] = useState([]);
  const [Digitalp, setDigitalp] = useState([]);
  const[imgpath,setImgpath]=useState('')
 





  useEffect(() => {
    fetch(
      "https://demo.webaiders.com/thewebaid/backend/public/api/designDescription"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        // console.log(path);
        if (data.status) {
          setDigital(data.data);
          // setImgpath(data.path);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  
  useEffect(() => {
    fetch("https://demo.webaiders.com/thewebaid/backend/public/api/designPoint")
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setDigitalp(data.data); // Store the API data in the MarketingP state
          setImgpath(data.path);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  return (
    <div>
      <div class="innerBanner">
      <img class="baner_img_page" src="img/default-banner.jpg" alt="Website Design and Development"/>
    </div>

    <div className="part1">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="web-img">
                <img src={`${imgpath}/${Digital.image}`} alt="" />
              </div>
            </div>

            <div className="col-md-6">
              <div className="ra-web-text">
                <h2>
                  Website <span>Design </span> <br /> Development
                </h2>
                <p>{Digital.description}</p>

                <div class="row">
                  <div class="col-lg-12 col-sm-12">
                    <ul class="cybersecurity-item">
                      {Digitalp.map((item, index) => (
                        <li key={index}>
                          <Link to={`/Ui`} state={{ id: item.id }}>
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                            {item.point}
                          </Link>
                        </li>
                      ))}

                    </ul>
                  </div>
                </div>

                {/* <a
                  href="https://thewebaid.com/website-design-development/"
                  class="default-btn mt-30"
                >
                  Know Details
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Digitalevelopment
