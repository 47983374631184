import React, { useState } from "react";
import "./paynow.css";

const Paynow = () => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [service, setservice] = useState("");
  const [agree, setAgree] = useState(false);
  const [errors, setErrors] = useState({});
  const [amount, setamount] = useState("");
  const [currency, setcurrency] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      // Form is valid, submit the data
      console.log("Form submitted successfully!");
      try {
        const apiUrl =
          "https://demo.webaiders.com/thewebaid/backend/public/api/process-transaction";
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            address,
            email,
            service,
            amount,
            currency,
          }),
        });
        const data = await response.json();
        console.log(data);
        if (data.status) {
          window.location.href = data.payment_url; // assuming the API response contains the PayPal URL in data.data.payment_url
        } else {
          console.log("No data or empty array received from the API.");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleReset = () => {
    setName("");
    setAddress("");
    setEmail("");
    setservice("");
    setAgree(false);
    setamount("");
    setcurrency("");
    setErrors({});
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (name === "" || /\d/.test(name)) {
      errors.name = "Please enter your name properly.";
      isValid = false;
    }

    if (address === "") {
      errors.address = "Please enter your address.";
      isValid = false;
    }

    if (email === "" || !email.includes("@")) {
      errors.email = "Please enter a valid email address.";
      isValid = false;
    }

    if (service === "") {
      errors.service = "Please select your course.";
      isValid = false;
    }

    if (!agree) {
      errors.agree = "Please agree to the above information.";
      isValid = false;
    }

    if (amount === "" || isNaN(amount) || amount < 0) {
      errors.amount = "Please enter a valid amount.";
      isValid = false;
    }

    if (currency === "") {
      errors.currency = "Please select a currency.";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  return (
    <div>
      <div className="innerBanner">
        <img
          className="baner_img_page"
          src="img/default-banner.jpg"
          alt="Website Design and Development"
        />
      </div>

      <div className="sec-space about-cms">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="payimg paybox">
                <img
                  src="img/pay.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="ra-form">
                <form onSubmit={handleSubmit} className="ra-main-form">
                  <h1>Pay Now</h1>
                  <p>
                    <input
                      type="text"
                      id="name"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      placeholder="Enter your full name"
                    />
                    {errors.name && (
                      <span className="error-message">{errors.name}</span>
                    )}
                  </p>
                  <p>
                    <input
                      type="text"
                      id="address"
                      value={address}
                      onChange={(event) => setAddress(event.target.value)}
                      placeholder="Enter your address"
                    />
                    {errors.address && (
                      <span className="error-message">{errors.address}</span>
                    )}
                  </p>
                  <p>
                    <input
                      type="text"
                      id="email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      placeholder="Enter your email"
                    />
                    {errors.email && (
                      <span className="error-message">{errors.email}</span>
                    )}
                  </p>
                  <p>
                    <select
                      id="service
"
                      value={service}
                      onChange={(event) => setservice(event.target.value)}
                    >
                      <option value="">Select service</option>
                      <option value="Mobile App">Mobile App</option>
                      <option value="website Development">
                        website Development
                      </option>
                      <option value="SEO">SEO</option>
                      <option value="Digital Marketing">
                        Digital Marketing
                      </option>
                    </select>
                    {errors.service && (
                      <span className="error-message">{errors.service}</span>
                    )}
                  </p>
                  <p>
                    <select
                      id="currency"
                      value={currency}
                      onChange={(event) => setcurrency(event.target.value)}
                    >
                      <option value="">Select currency</option>
                      <option value="USD">US Dollar ($)</option>
                      <option value="EUR">Euro (€)</option>
                      <option value="GBP">UK Pound (£)</option>
                      <option value="CAD">Canadian Dollar (CAD)</option>
                      <option value="AUD">Australian Dollar (AUD)</option>
                    </select>
                    {errors.currency && (
                      <span className="error-message">{errors.currency}</span>
                    )}
                  </p>
                  <p>
                    <input
                      type="number"
                      id="amount"
                      value={amount}
                      onChange={(event) => setamount(event.target.value)}
                      placeholder="Enter the amount"
                    />
                    {errors.amount && (
                      <span className="error-message">{errors.amount}</span>
                    )}
                  </p>
                  <p className="ra-check">
                    <input
                      type="checkbox"
                      id="agree"
                      checked={agree}
                      onChange={(event) => setAgree(event.target.checked)}
                    />
                    <label htmlFor="agree" className="ra-agree">
                      I agree to the above information
                    </label>
                    {errors.agree && (
                      <span className="error-message">{errors.agree}</span>
                    )}
                  </p>
                  <div className="ra-submit-button d-md-flex justify-content-between">
                   <input type="submit" value="pay now" />
                    <div className="ra-main-reset">
                      <input
                        type="reset"
                        value="Reset"
                        onClick={handleReset}
                        className="ra-reset-button"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paynow;
