import { useState, useEffect } from "react";
import "./home.css";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";

function Home() {
  const [Digital, setDigital] = useState([]);
  const [Digitalp, setDigitalp] = useState([]);
  const [Video, setVideo] = useState([]);
  const [Marketing, setMarketing] = useState([]);
  const [MarketingP, setMarketingP] = useState([]);
 
  const [imgpath, setImgpath] = useState("");
  const [vidpath, setVidpath] = useState("");
  const [CustomWA, setCustomWA] = useState("");
  const [MobileA, setMobileA] = useState("");
  
  useEffect(() => {
    fetch("https://demo.webaiders.com/thewebaid/backend/public/api/homebanner")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        // console.log(path);
        if (data.status) {
          setVideo(data.data);
          setVidpath(data.path);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetch(
      "https://demo.webaiders.com/thewebaid/backend/public/api/designDescription"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        // console.log(path);
        if (data.status) {
          setDigital(data.data);
          // setImgpath(data.path);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetch(
      "https://demo.webaiders.com/thewebaid/backend/public/api/marketingDescription"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        // console.log(path);
        if (data.status) {
          setMarketing(data.data);
          setImgpath(data.path);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

 

  useEffect(() => {
    fetch(
      "https://demo.webaiders.com/thewebaid/backend/public/api/marketingPoint"
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setMarketingP(data.data); // Store the API data in the MarketingP state
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetch("https://demo.webaiders.com/thewebaid/backend/public/api/designPoint")
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setDigitalp(data.data); // Store the API data in the MarketingP state
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);



  useEffect(() => {
    fetch(
      "https://demo.webaiders.com/thewebaid/backend/public/api/webApplicationDescription"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        // console.log(path);
        if (data.status) {
          setCustomWA(data.data);
          setImgpath(data.path);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);



  useEffect(() => {
    fetch(
      "https://demo.webaiders.com/thewebaid/backend/public/api/mobileApplicationDescription"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        // console.log(path);
        if (data.status) {
          setMobileA(data.data);
          setImgpath(data.path);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  // const{data,path} = vedio;
  // let data1 = data?data.bannervideo:"";
  // console.log(data);
  // console.log(Digital);
  console.log(MarketingP);
  // console.log(vidpath);

  return (
    <div>
      <div className="home">
       
        <video
          style={{ width: "100%" }}
          src={`${vidpath}/${Video.bannervideo}`}
          type="video/mp4"
          loop
          autoPlay
          muted
        />

        <div className="home-content">
          <div className="ra-overlay-text">
            <h1>WEBAID</h1>
            <span>Create.Code.Connect</span>

            <div className="typing-text">
              <div className="container">
                <Typewriter
                  onInit={(typewriter) => {
                    const text =
                      "Unmatched Ingenuity Embracing Complexity:Leading the Digital Frontier";
                    let charIndex = 0;

                    function typeAndDelete() {
                      if (charIndex < text.length) {
                        typewriter.typeString(text[charIndex]);
                        charIndex++;
                      } else {
                        typewriter.deleteAll();
                        charIndex = 0;
                      }
                      typewriter.pauseFor(50).start(); // adjust the pause time to your liking
                      setTimeout(typeAndDelete, 50); // adjust the timeout time to your liking
                    }

                    typeAndDelete();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="part1">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="web-img">
                <img src={`${imgpath}/${Digital.image}`} alt="" />
              </div>
            </div>

            <div className="col-md-6">
              <div className="ra-web-text">
                <h2>
                  Website <span>Design </span> <br /> Development
                </h2>
                <p>{Digital.description}</p>

                <div class="row">
                  <div class="col-lg-12 col-sm-12">
                    <ul class="cybersecurity-item">
                      {Digitalp.map((item, index) => (
                        <li key={index}>
                          <Link to={`/Ui`} state={{ id: item.id }}>
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                            {item.point}
                          </Link>
                        </li>
                      ))}

                    </ul>
                  </div>
                </div>

                <a
                  href="/Website"
                  class="default-btn mt-30"
                >
                  Know Details
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="part2"
        style={{
          backgroundImage: "url(./img/web-application.jpg)",
        }}
      >
        <div className="container">
          <div className="row ">
            <div className="col-md-6">
              <div className="web_application_text">
                <h2>
                  Custom <span>Web Application</span> Development
                </h2>
                <p>{CustomWA.description}</p>
                <div className="row">
                  <div className="col-lg-12 col-sm-12"></div>
                </div>
                <div className="part2_default-btn">
                  <a
                    href="/WebApplication"
                    className="default-btn"
                  >
                    Know Details
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="web_application_img">
                <img src={`${imgpath}/${CustomWA.image}`} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="part3">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="mobile-img">
                <img src={`${imgpath}/${MobileA.image}`} alt="" srcset="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mobile_application_text">
                <h2>
                  Mobile <span> Application</span> Development
                </h2>
                <p>
                  {MobileA.description}
                </p>

                <div className="part3_default-btn">
                  <a
                    href="/Mobile"
                    className="default-btn"
                  >
                    Know Details
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="part4">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="ra-Marketing-text">
                <h2>
                  Content <span>Marketing </span>   
                  Management
                </h2>
                <p>{Marketing.description}</p>

                <div className="row">
                  <div className="col-lg-12 col-sm-12">
                    <ul className="cybersecurity-item">
                      {MarketingP.map((item, index) => (
                        <li key={index}>
                          <Link to={`/SocialM`} state={{ id: item.id }}>
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                            {item.point}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <a
                  href="/Marketing"
                  className="default-btn mt-30"
                >
                  Know Details
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="Marketing-img">
                <img src={`${imgpath}/${Marketing.image}`} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

     
    </div>
  );
}

export default Home;
