import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./home.css";
import "./websiteDevelopment.css";

function ContentMarketing() {
  const [Marketing, setMarketing] = useState([]);
  const [MarketingP, setMarketingP] = useState([]);
  const[imgpath,setImgpath]=useState('')

  useEffect(() => {
    fetch(
      "https://demo.webaiders.com/thewebaid/backend/public/api/marketingDescription"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        // console.log(path);
        if (data.status) {
          setMarketing(data.data);
          setImgpath(data.path);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetch(
      "https://demo.webaiders.com/thewebaid/backend/public/api/marketingPoint"
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setMarketingP(data.data); // Store the API data in the MarketingP state
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div class="innerBanner">
        <img
          class="baner_img_page"
          src="img/default-banner.jpg"
          alt="Website Design and Development"
        />
      </div>

      <div className="part4">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="ra-Marketing-text">
                <h2>
                  Content <span>Marketing </span> <br />
                  Management
                </h2>
                <p>{Marketing.description}</p>

                <div className="row">
                  <div className="col-lg-12 col-sm-12">
                    <ul className="cybersecurity-item">
                      {MarketingP.map((item, index) => (
                        <li key={index}>
                          <Link to={`/SocialM`} state={{ id: item.id }}>
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                            {item.point}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="Marketing-img">
                <img
                  src={`${imgpath}/${Marketing.image}`}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentMarketing;
