import React from "react";
import { useState, useEffect } from "react";
import "./footer.css";

function Footer() {

  const [Inquiries, setInquiries] = useState([]);


  useEffect(() => {
    fetch("https://demo.webaiders.com/thewebaid/backend/public/api/inquiries")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          setInquiries(data.data);
          // setImgpath(data.path);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);



  return (
    <div>

<div className="part5">
        <div className="container">
          <div className="ra_part5_text">
            <h1>
              For General <span>Inquiries</span>
            </h1>

            <p>{Inquiries.Description}</p>

            <a href="" class="default-btn mt-30">
              Contact Now
            </a>
          </div>
        </div>
      </div>


      <div className="footer-bottom-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7">
              <div className="copy-right">
                <p>Copyright © 2024 Webaid. All rights reserved. </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="paybtn-ra">
                <a href="/VerifyOtp">
                  <img
                    src="./img/pay-btn.png"
                    alt="" className="img-fluid"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
