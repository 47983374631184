import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navber.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <nav
        className={`navbar fixed-top navbar-expand-lg ${isScrolled ? "scrolled" : ""}`}
      >
        <div className="container-fluid ra-nav-menu">
          <img src="img/Asset-1otp.png" className="ra-nav-logo" alt="logo" />
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="collapse navbar-collapse justify-content-center pt-3"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link text-white active" aria-current="page" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Services
                </a>
                <ul className="dropdown-menu">
                  <li className="nav-item dropend">
                    <a
                      className="nav-link dropdown-toggle"
                      href="/Website"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Website Design Development
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <Link
                          to={`/Ui`}
                          state={{ id: 1 }}
                          className="dropdown-item"
                        >
                          UI/UX
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/CustomWeb`}
                          state={{ id: 3 }}
                          className="dropdown-item"
                        >
                          Custom Web Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/Ecommerce`}
                          state={{ id: 2 }}
                          className="dropdown-item"
                        >
                          E-Commerce Solution
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/WebApplication">
                      Custom Web Application Development
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/Mobile">
                      Mobile Application Development
                    </a>
                  </li>
                  <li className="nav-item dropend">
                    <a
                      className="nav-link dropdown-toggle"
                      href="/Marketing"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Content Marketing Management
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <Link
                          to={`/SocialM`}
                          state={{ id: 1 }}
                          className="dropdown-item"
                        >
                          Social Media
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/Copywriting`}
                          state={{ id: 2 }}
                          className="dropdown-item"
                        >
                          Copywriting
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/SEO`}
                          state={{ id: 3 }}
                          className="dropdown-item"
                        >
                          SEO
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Company
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a href="/Aboutus" className="dropdown-item">
                      About us
                    </a>
                  </li>
                  <li>
                    <a href="/Whyus" className="dropdown-item">
                      Why us
                    </a>
                  </li>
                  <li>
                    <a href="/Careers" className="dropdown-item">
                      Careers
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a href="/Contact" className="nav-link">
                  Contact Us
                </a>
              </li>
            </ul>
            <div class="ra-nav-mail-mobile ">
                    <a href="mailto:connect@thewebaid.com">
                      <i class="fa fa-envelope" aria-hidden="true"></i>
                                <span>connect@thewebaid.com</span>
                     
                    </a>
                  </div>
          </div>
          <div class="ra-nav-mail">
                    <a href="mailto:connect@thewebaid.com">
                      <i class="fa fa-envelope" aria-hidden="true"></i>
                                <span>connect@thewebaid.com</span>
                     
                    </a>
                  </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
