import "./App.css";
import { BrowserRouter as Router,Switch, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./navber";
import Home from "./component/home";
// import Home from "./component/home";
import Website from "./component/websiteDevelopment";
import WebApplication from "./component/Custom_Web_Application";
import Mobile from "./component/mobileApplication";
import Marketing from "./component/contentMarketing";
import Contact from "./contact";
import Ui from "./component/UI";
import Ecommerce from "./component/E-CommerceSolution";
import CustomWeb from "./component/CustomWebDevelopment";
import SocialM from "./component/SocialMedia";
import SEO from "./component/SEO";
import Copywriting from "./component/Copywriting";
import Whyus from "./component/whyus";
import Aboutus from "./component/AboutUs";
import Careers from "./component/Careers";
import Footer from "./footer";
import Paymow from "./component/paymow";
import VerifyOtp from "./component/verifyotp";

function App() {
  return (
    <>
      
        <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Website" element={<Website />} />
          <Route path="/WebApplication" element={<WebApplication />} />
          <Route path="/Mobile" element={<Mobile />} />
          <Route path="/Marketing" element={<Marketing />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Ui" element={<Ui />} />
          <Route path="/Ecommerce" element={<Ecommerce />} />
          <Route path="/CustomWeb" element={<CustomWeb />} />
          <Route path="/SocialM" element={<SocialM />} />
          <Route path="/SEO" element={<SEO />} />
          <Route path="/Copywriting" element={<Copywriting />} />
          <Route path="/Whyus" element={<Whyus />} />
          <Route path="/Aboutus" element={<Aboutus />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/Paymow" element={<Paymow />} />
          <Route path="/VerifyOtp" element={<VerifyOtp />} />
          </Routes>
          <Footer />
        </Router>
        
    </>
  );
}

export default App;
