import React, { useState, useEffect, useRef } from 'react';
import './verifyotp.css';

const VerifyOtp = () => {
  const [otp, setOtp] = useState(['', '', '', '']);
  const inputRefs = useRef([]);

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  const handleChange = (value, index) => {
    if (value.length > 1) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSubmit = () => {
    const otpValue = otp.join('');
    const expectedOTP = '0224';
    if (otpValue === expectedOTP) {
      window.location.href = '/Paymow';
    } else {
      alert('Invalid OTP. Please try again.');
    }
  };

  return (
    <div className="one-time-form">
      <div className="container one-form">
        <div className="icon text-center pb-3">
          <img src="img/Asset-1otp.png" className="img-fluid" alt="img" />
        </div>
        <h4 className="text-center">Enter Coupon Code</h4>
        <form id="otpForm" className="text-center" onSubmit={(e) => e.preventDefault()}>
          <div className="input-field">
            {otp.map((_, index) => (
              <input
                key={index}
                type="number"
                value={otp[index]}
                onChange={(e) => handleChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={(el) => (inputRefs.current[index] = el)}
                disabled={index !== 0 && !otp[index - 1]}
                required
              />
            ))}
          </div>
          <input
            id="verifyBtn"
            type="button"
            value="Submit"
            onClick={handleSubmit}
            className="verify-btn"
            disabled={otp.includes('')}
          />
        </form>
      </div>
    </div>
  );
};

export default VerifyOtp;
