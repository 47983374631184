import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./home.css";
import "./websiteDevelopment.css";

function CustomWeb() {
  const { state } = useLocation();
  console.log(state);

  const [CustomWeb, setCustomWeb] = useState([]);
  const[imgpath,setImgpath]=useState('')

  useEffect(() => {
    const apiUrl =
      "https://demo.webaiders.com/thewebaid/backend/public/api/designPointDescription";
    // e.g., { id: 1, name: 'John' }

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: state }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          setCustomWeb(data.data);
          setImgpath(data.path);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div class="innerBanner">
        <img
          class="baner_img_page"
          src="img/web-application.jpg"
          alt="Website Design and Development"
        />
      </div>

      <div className="part3">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="mobile-img">
                <img
                  src={`${imgpath}/${CustomWeb.image}`}
                  alt=""
                  srcset=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mobile_application_text">
                <h2>{CustomWeb.point}</h2>
                <p>{CustomWeb.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomWeb;
