import { useState, useEffect } from "react";
import "./home.css";
import "./websiteDevelopment.css";

function Whyus() {


  const [whyus, setwhyus] = useState([]);
  const [imgpath, setImgpath] = useState("");
 
  useEffect(() => {
    fetch(
      "https://demo.webaiders.com/thewebaid/backend/public/api/whyus"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        // console.log(path);
        if (data.status) {
          setwhyus(data.data);
          setImgpath(data.path);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  return (
    <div>
      <div class="innerBanner">
        <img
          class="baner_img_page"
          src="img/default-banner.jpg"
          alt="Website Design and Development"
        />
      </div>

      <div className="ra-part3">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="mobile-img">
                <img src={`${imgpath}/${whyus.image}`} alt="" srcset="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mobile_application_text">
                <h2>
                  <span> Why Us</span>
                </h2>
                <p>
                  {whyus.description}
                  </p>

                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Whyus;
