import { useState, useEffect } from "react";
import "./home.css";
import "./websiteDevelopment.css";

function Web_Application() {
  const [CustomWA, setCustomWA] = useState("");
  const[imgpath,setImgpath]=useState('')

  useEffect(() => {
    fetch(
      "https://demo.webaiders.com/thewebaid/backend/public/api/webApplicationDescription"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        // console.log(path);
        if (data.status) {
          setCustomWA(data.data);
          setImgpath(data.path);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div class="innerBanner">
        <img
          class="baner_img_page"
          src="img/default-banner.jpg"
          alt="Website Design and Development"
        />
      </div>

      <div
        className="part2"
        style={{
          backgroundImage: "url(./img/web-application.jpg)",
        }}
      >
        <div className="container">
          <div className="row ">
            <div className="col-md-6">
              <div className="web_application_text">
                <h2>
                  Custom <span>Web Application</span> Development
                </h2>
                <p>{CustomWA.description}</p>
                <div className="row">
                  <div className="col-lg-12 col-sm-12"></div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="web_application_img">
                <img
                  src={`${imgpath}/${CustomWA.image}`}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Web_Application;
